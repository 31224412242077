.users-container {
    padding: 20px;
    max-width: 80%;
    margin: 0 auto;
    
  }
  .users-container-header {
    background-color: gray;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 60px;
  }
  h1 {
    text-align: center;
    color: #c43539;
    margin-bottom: 20px;
  }
  
  .logout-button {
    display: block;
    margin: 0 auto 20px auto;
    padding: 10px 20px;
    background-color: #c43539;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .users-table {
    width: 100%;
    overflow-x: auto;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border-radius: 10px;
    color: #305159;
  }
  
  thead {
    background-color: #c84549;
    color: #fff;
  }
  
  th, td {
    padding: 10px;
    text-align: left;
  }
  
 
  
  tbody tr:hover {
    opacity: 0.7;
  }
  
  td {
    border-bottom: 1px solid #ddd;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .users-container {
      padding: 10px;
      max-width: 100%;
    }
  
    table, thead, tbody, th, td, tr {
      display: block;
    }
  
    thead tr {
      display: none;
    }
  
    tr {
      margin-bottom: 15px;
      border-bottom: 1px solid #ddd;
      padding-bottom: 10px;
    }
  
    td {
      padding-left: 50%;
      position: relative;
      text-align: left;
      border: none;
    }
  
    td:before {
      content: attr(data-label);
      position: absolute;
      left: 10px;
      width: calc(50% - 20px);
      padding-right: 10px;
      text-align: left;
      font-weight: bold;
    }
  }
  