body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
}

.logo-img-users {
  width: 150px;
}

.logo-users {
  display: flex;
  align-items: center;
  width: 50%;
  padding-left: 5%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  border:0;
  outline:0;
  height: 25px;
  width: 90%;
  color: gray;
  padding: 0 10px;
  margin-top: 5px;
  font-size: 12px;
}

input:focus {
  outline:none!important;
}

input[type="date"] {
  -webkit-appearance: none; 
  border:0;
  outline:0;
  height: 25px;
  width: 90%;
  color: gray;
  padding: 0 10px;
  margin-top: 5px;
  font-size: 12px;
}

.check-input {
  width: 10px;
  margin: 6px 5px;
}

.container-main {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh; 
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.container-main::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 160px;
  background-image: url('https://interactiva.net.co/imagenes/avianca/Header.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
}

.container-main::after {
  content: '';
  position: absolute;
  top: 159px;
  left: 0;
  width: 100%;
  height: calc(100% - -55px);
  background-image: url('https://interactiva.net.co/imagenes/avianca/Fondo.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
}

.container-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 8%;
  position: relative;
  z-index: 1000;
}

.logo {
  display: flex;
  margin-top: 7%;
  justify-content: center;
}

.logo-img {
  width: 200px;
}

.imgIco{
  width: 180px;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0rem;
}

.aviancaIco{
  position: absolute;
  top: 10px;
  right: 10px;
  width: 80px;
  height: 40px;
  background-image: url('https://interactiva.net.co/imagenes/avianca/Avianca-Logo.png');
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
}

.container-form {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  width: 400px;
  padding: 10px 10px;
  background-color: #5f0000;
  box-sizing: border-box;
  color: white !important;
}

.button-container {
  display: flex;
  margin: 20px 0;
  flex-direction: column;
  align-items: center;
}

.container-logo-star {
  text-align: center;
  margin-top: 7%;
  margin-bottom: 50px;
}

.container-logo-star-logo {
  width: 200px;
}

.text-form {
  color: #284449;
  font-size: 12px;
}

.form-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;
  font-size: 16px;
}

.container-logoa {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.logoa-img {
  width: 20px;
}

.form-group {
  margin: 0 25px;
}

.text-general {
  color: #fefafb
}

.text-tyc {
  font-size: 9px;
}

.text-description-small {
  font-size: 11px;
  width: 400px;
  text-align: center;
  margin-top: 2%;
}

.form-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
}

.text-description {
  margin-top: 5%;
  width: 400px;
  text-align: center;
  font-size: 24px;
  line-height: 1.1;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c8b8a4;
  color: #5b0100;
  width: 150px;
  height: 38px;
  border-radius: 40px;
  font-size: 17px;
  cursor: pointer;
  border-color: white;
  box-shadow: none;
  font-weight: bold;
}

.button-container-user {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  width: 50%;
}

.button-user {
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  padding-right: 10%;
}

.button:hover {
  background-color: #9d6665;
  color: #fff;
}

.underline {
  text-decoration: underline;
}

.underline-none {
  text-decoration: none;
}

.arrow1 {
  position: absolute;
  width: 200px;
  top: 0;
  left: 10%;
}

.arrow2 {
  position: absolute;
  width: 200px;
  bottom: 0;
  right: 15%;
}

.nube1 {
  position: absolute;
  width: 150px;
  left: -75px;
  top: 30%;
}

.nube2 {
  position: absolute;
  width: 300px;
  left: -150px;
  top: 60%;
}

.nube3 {
  position: absolute;
  width: 150px;
  right: -70px;
  top: 10%;
}

.nube4 {
  position: absolute;
  width: 200px;
  right: -150px;
  top: 30%;
}

.nube5 {
  position: absolute;
  width: 200px;
  right: -50px;
  top: 70%;
}

.classLink{
  color: white;
  text-align: justify;
}

@media (max-width: 900px) {
  .arrow1 {
    width: 100px;
    left: 0;
  }

  .arrow2 {
    width: 100px;
    right: 0;
  }
}

@media (max-width: 600px) {
  .text-description, .text-description-small, .container-form  {
      width: 100%;
  }

  .form-item {
      font-size: 12px;
  }

  .text-description {
      font-size: 16px;
  }

  .logo-img-users {
    width: 100px;
  }

  .text-description {
    background-color: rgb(109 80 80 / 50%);
    border-radius: 10px;
    padding: 10px;
  }

  .container-form {
    margin: 10px 0;
  }

  .imgIco{
    padding-top: 18rem;
  }

  .button-container {
    margin: 5px 0;
  }

  .logo-img {
    width: 150px;
  }

  .container-main {
    height: 100vh;
    overflow: scroll !important;
  }

}

.input-select {
  width: 96%;
  padding: 5px;
  padding-left: 9px;
  font-size: 12px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #333;
  margin-top: 5px;
  appearance: none; /* Elimina la flecha del navegador */
  -webkit-appearance: none; /* Elimina la flecha en Safari */
  -moz-appearance: none; /* Elimina la flecha en Firefox */
  box-sizing: border-box; /* Asegura que el padding no afecte el tamaño del input */
}

.input-select:focus {
  border-color: #007BFF;
  outline: none;
}

.input-select-wrapper {
  position: relative;
}

.input-select-wrapper::after {
  content: '\25BC'; /* Carácter de la flecha hacia abajo */
  font-size: 12px;
  position: absolute;
  right: 24px;
  top: 55%;
  transform: translateY(-50%);
  pointer-events: none; /* Hace que la flecha no interfiera con la selección */
  color: black;
}

.checkbox{
  border: 0;
  outline: 0;
  height: 13px;
  width: 5%;
  color: gray;
  padding: 0 10px;
  margin-top: 5px;
  font-size: 12px;
  margin-right: 0.5rem;
}

.cointainerCheck{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.cointainerChecks{
  display: flex;
}

.radioButton {
  width: 20px; /* Ajusta el tamaño según tus necesidades */
  height: 20px;
  margin-right: 5px; /* Espacio entre el radio y la etiqueta */
}


@media (min-width: 1024px) {
  .container-main::before {
    display: none; /* Oculta el header */
  }

  .container-main::after {
    top: 0; /* Ajusta para ocupar todo el fondo */
    height: 100%; /* Opción para escritorio */
  }

  .aviancaIco{
    top: 22px;
    right: 34px;
    width: 120px;
    height: 75px;
  }
}


.input-container {
  position: relative;  /* Necesario para colocar el placeholder encima del input */
}

/* Estilo del placeholder (span) */
.date-placeholder {
  position: absolute;
  top: 50%; 
  left: 11px;
  transform: translateY(-50%);  /* Centrado vertical */
  color: #aaa;
  pointer-events: none;  /* Hace que el placeholder no interfiera con la interacción del usuario */
  font-size: 13px;
  transition: opacity 0.3s ease;
}

@media (min-width: 769px) {
  .date-placeholder {
    display: none;
  }
}
